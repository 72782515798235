/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #f3c2d5;
  --ion-color-primary-rgb: 243, 194, 213;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #d6abbb;
  --ion-color-primary-tint: #f4c8d9;
  --ion-color-primary-neu-up: #ffdff5;
  --ion-color-primary-neu-down: #cfa5b5;
  --ion-color-primary-neu-gradient: linear-gradient(145deg, #ffd0e4, #dbafc0);
  --ion-color-secondary: #2e4b70;
  --ion-color-secondary-rgb: 46, 75, 112;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #284263;
  --ion-color-secondary-tint: #435d7e;
  --ion-color-secondary-neu-up: #3a5f8d;
  --ion-color-secondary-neu-down: #223853;
  --ion-color-secondary-neu-gradient: linear-gradient(145deg, #315078, #294465);
  --ion-color-tertiary: #d3c4f2;
  --ion-color-tertiary-rgb: 211, 196, 242;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #baacd5;
  --ion-color-tertiary-tint: #d7caf3;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-purple: #2e4b70;
  --ion-color-purple-rgb: 46, 75, 112;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #284263;
  --ion-color-purple-tint: #435d7e;

  --ion-font-family: 'Quicksand', sans-serif;
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

body.boy {
  --ion-color-primary: #2e4b70;
  --ion-color-primary-rgb: 46, 75, 112;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #284263;
  --ion-color-primary-tint: #435d7e;
  --ion-color-primary-neu-up: #355681;
  --ion-color-primary-neu-down: #27405f;
  --ion-color-primary-neu-gradient: linear-gradient(145deg, #315078, #294465);

  --ion-color-secondary: #f3c2d5;
  --ion-color-secondary-rgb: 243, 194, 213;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d6abbb;
  --ion-color-secondary-tint: #f4c8d9;
  --ion-color-secondary-neu-up: #ffdff5;
  --ion-color-secondary-neu-down: #cfa5b5;
  --ion-color-secondary-neu-gradient: linear-gradient(145deg, #ffd0e4, #dbafc0);
}

ion-header[class*="header"] ion-toolbar[class*="in-toolbar"] {
  --border-width: 0;
}

ion-header[class*="header"]::after {
  background-image: none;
}

ion-header ion-toolbar ion-button {
  --box-shadow: none;
}

ion-toolbar.title {
  background: transparent;
  --background: transparent;

  --padding-start: 0;
  --padding-end: 0;
  --border-width: 0;

  ion-title {
    padding-left: 16px;
    padding-right: 16px;
  }

  h1 {
    font-weight: bold;
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: 900px;
  }
}

ion-list {
  --ion-item-background: transparent;
}

ion-item.with-neu,
ion-segment.with-neu,
.neu-button {
  --ion-item-background: transparent;

  border-radius: 10px;
  background: #f4f5f8;
  box-shadow:  5px 5px 10px #cfd0d3, -5px -5px 10px #ffffff;

  &.ion-activated,
  &.item-has-focus {
      background: #f4f5f8;
      box-shadow: inset 5px 5px 10px #cfd0d3, inset -5px -5px 10px #ffffff;
  }
}

ion-segment {
  min-height: 44px;
}

main {
  margin: 0 auto;
  max-width: 900px;
}

.u-sticky {
  position: sticky;
  top: 0;
}

.neu-button {
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 2.8em;
  justify-content: center;
  padding: 0 var(--padding-start);
  user-select: none;
  max-width: 150px;
}

.welcome-modal {
  --max-height: 500px;
  --max-width: 80vw;

  .modal-wrapper {
    border-radius: 25px;
  }
}
