/* quicksand-regular - latin */

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('/assets/fonts/quicksand-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/quicksand-v20-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* quicksand-700 - latin */

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('/assets/fonts/quicksand-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/quicksand-v20-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
