.welcome-page {
    ion-content {
        --overlow: hidden;
    }

    ion-grid {
        --ion-grid-padding: 0;
        display: flex;
        flex-flow: column;
        height: 100%;

        ion-row:last-child {
            margin-top: auto;
        }

        ion-col {
            // --ion-grid-column-padding: 0;

            ion-button {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    h2 {
        font-weight: bold;
    }
}
